<template>
  <div>
    <v-data-table :footer-props="{ itemsPerPageOptions, itemsPerPageText }" :header-props="{ sortByText: `Trier par` }"
      :headers="headers" :items="etablissement" class="elevation-1 ma-4 pa-4" :page="page" :pageCount="numberOfPages"
      :options.sync="options" :server-items-length="totalEtablissement" :loading="loading"
      loading-text="Chargement des éléments" mobile-breakpoint="850" sort-by="uai">
      <!-- Tableau  ------------------------------------------------------------------------------------>
      <template v-slot:top>
        <v-toolbar-title class="mx-2" v-if="!$vuetify.breakpoint.smAndUp">Etablissement
        </v-toolbar-title>
        <v-toolbar flat>
          <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Etablissement</v-toolbar-title>
          <v-divider class="mx-2" inset vertical v-if="$vuetify.breakpoint.smAndUp"></v-divider>
          <v-toolbar-title class="mx-4">

          </v-toolbar-title>
          <v-switch class="tison-switch" @click="switchEtabMode" v-model="isTison">
            <template v-slot:label="">
              <span>Etablissements {{ isTison ? "TISON" : "ARBS" }}</span>
            </template>
            <template v-slot:messages=""></template>
          </v-switch>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche" single-line hide-details
            class="mx-4"></v-text-field>
          <v-spacer></v-spacer>
          <NextButton class="btn btn-default hidden-md-and-down" @clicked="exportEtab" width="auto" color="primary"
            id="export-csv" text="CSV" :loading="loading" icon="mdi-download" />
        </v-toolbar>
      </template>
      <!-- Action Button ------------------------------------------------------------------------------->
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <Tooltip textToolTip="Export des comptes.">
            <v-icon class="mr-2" color="primary" @click="
              exportQrCode(item.id);
            clicked = true;
            " :disabled="clicked || isTison">
              mdi-download
            </v-icon>
          </Tooltip>

          <Tooltip textToolTip="Historique des commandes.">
            <v-icon class="mr-2" color="primary" @click="
              $router
                .push({ path: `/admin/historique?search=${item.id}` })
                .catch((error) => {
                  if (error.name !== 'NavigationDuplicated') {
                    throw error;
                  }
                });
            clicked = true;
            "
              :disabled="clicked || user.role === `GESTIONNAIRE_ETABLISSEMENT` || user.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`">
              mdi-clipboard-text-clock-outline
            </v-icon>
          </Tooltip>
          <Tooltip textToolTip="Nouvelle commande.">
            <v-icon class="mr-2" color="primary" @click="
              newcommande(item.id);
            clicked = true;
            "
              :disabled="clicked || user.role === `GESTIONNAIRE_ETABLISSEMENT` || user.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE` || isTison">
              mdi-cart-minus
            </v-icon>
          </Tooltip>
          <Tooltip textToolTip="Statistiques de l'etablissement.">
            <v-icon class="mr-2" color="green" @click="
              $router
                .push({ path: `/admin/etablissement/statistique/${item.id}` })
                .catch((error) => {
                  if (error.name !== 'NavigationDuplicated') {
                    throw error;
                  }
                });
            clicked = true;
            " :disabled="isTison || clicked">
              mdi-chart-line
            </v-icon>
          </Tooltip>
          <Tooltip textToolTip="Etablissement ENT.">
            <v-icon class="mr-2" color="primary" @click="redirectENT(item)"
              :disabled="isTison || clicked || user.role === `GESTIONNAIRE_ETABLISSEMENT` || user.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`">
              {{ item.ent ? 'mdi-monitor' : 'mdi-monitor-off' }}
            </v-icon>
          </Tooltip>
          <Tooltip textToolTip="Réinitialiser statistiques">
            <v-icon class="mr-2" color="primary" @click="prepareStatsReset(item.id)"
              :disabled="isTison || clicked || user.role === `GESTIONNAIRE_ETABLISSEMENT` || user.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`">
              mdi-arrow-u-left-top
            </v-icon>
          </Tooltip>
        </v-row>
      </template>
      <template v-slot:no-data>
        <v-btn name="Rafraîchir" color="primary" @click="initialize">
          <v-icon> mdi-refresh</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog max-width="800px" v-model="resetStatsDialog">
      <v-card>
        <v-card-title class="text-h5 text-break justify-center">
            Réinitialisation des statistiques des utilisateurs
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-center">
            <p>Souhaitez-vous réinitialiser les statistiques de consultation des ressources des utilisateurs de cet établissement ?</p>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn large size="large" class="primary" @click="resetEstablishmentStats()">Oui</v-btn>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-btn @click="cancelStatsReset()" large size="large" class="secondary">Non</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AdminService from "./../../services/AdminService";
import Tooltip from "../Utility/Tooltip.vue";
import NextButton from "./../Utility/NextButton.vue";
import Utils from "@/utils/utils";

export default {
  components: {
    Tooltip,
    NextButton,
  },
  data: () => ({
    searchTimer: undefined,
    resetStatsDialog: false,
    clicked: false,
    isTison: false,
    isENT: false,
    search: "",
    resetEtbId: null,
    headers: [
      { text: "ID Etab*", value: "id", align: "start" },
      { text: "UAI", value: "uai" },
      { text: "Nom", value: "nom" },
      { text: "Code Postal", value: "cp" },
      { text: "Ville", value: "ville" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsPerPageOptions: [10, 25, 50, 1000],
    itemsPerPageText: `Lignes par page`,
    page: 1,
    etablissement: [],
    totalEtablissement: 0,
    numberOfPages: 0,
    pageCount: 0,
    loading: false,
    size: 10,
    options: {},
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    search: function () {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.options.page = 1;
        this.initialize();
      }, 1000)
    },
    options: {
      handler() {
        this.initialize();
      },
    },
    deep: true,
  },
  // watchEffect(isENT): {

  // },

  mounted() {
    this.initialize();
    this.resetStoreOrder();
  },

  methods: {
    resetStoreOrder() {
      this.$store.state.order = {
        devis: [],
        panier: [],
        isLoad: false,
        additionalsBooks: [],
      };
    },
    switchEtabMode() {
      this.initialize()
    },
    initialize() {
      this.loading = true;
      let { page, itemsPerPage, sortBy, sortDesc } = this.options;

      let pageNumber = page - 1;
      AdminService.getEtablissement(
        pageNumber,
        itemsPerPage,
        sortBy,
        sortDesc,
        this.search,
        this.isTison
      )
        .then(({ data }) => {
          this.loading = false;
          this.etablissement = data.content;
          this.totalEtablissement = data.totalElements;
          this.numberOfPages = data.totalPages;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Une erreur est survenue",
          });
          this.loading = false;
        });
    },
    async exportEtab() {
      const exportData = await AdminService.exportEtablissements(this.isTison)
      Utils.downloadFile(exportData.data)
    },
    exportQrCode(idEtab) {
      this.loading = true;
      AdminService.exportQrCode(idEtab)
        .then((res) => {
          const data =
            "data:application/octet-stream;charset=utf-16le;base64," + res.data;
          const link = document.createElement("a");
          link.setAttribute("download", `export_etablissement_${idEtab}.pdf`);
          link.setAttribute("href", data);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.loading = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Le fichier a été téléchargé",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Une erreur est survenue",
          });
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
          this.clicked = false;
        });
    },
    newcommande(id) {
      this.$store.state.order = {
        devis: [],
        panier: [],
        isLoad: false,
        additionalsBooks: [],
      };
      this.$router.push({ path: `/admin/order/${id}` }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    redirectENT(item) {
      this.$router.push(`/admin/etablissement/${item.id}`)
    },
    prepareStatsReset(etbId) {
      this.resetEtbId = etbId
      this.resetStatsDialog = true
    },
    cancelStatsReset() {
      this.resetEtbId = null
      this.resetStatsDialog = false
    },
    resetEstablishmentStats() {
      AdminService.resetEstablishmentStats(this.resetEtbId).finally(() => this.cancelStatsReset())
    },
  },
};
</script>
<style lang="scss">
.tison-switch {
  .v-input__slot {
    margin-bottom: 0;
  }

  .v-messages {
    display: none
  }
}
</style>
