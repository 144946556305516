<template>
  <v-card height="100%" color="backgroundColor">
    <v-row class="ma-0 pa-0"><h1 class="primary--text ma-4">Statistiques Utilisateur</h1></v-row>
    <v-row class="ma-0 pa-">
      <v-col cols="12" md="4" lg="3">
        <StatistiqueNav :user="user" />
      </v-col>
      <v-col cols="12" md="8" lg="9" align-center>
        <StatistiqueMain :datas="dataUser" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import StatistiqueNav from "./../components/StatistiqueVueComponent/StatistiqueNav.vue";
import StatistiqueMain from "./../components/StatistiqueVueComponent/StatistiqueMain.vue";
import AdminService from "../services/AdminService";
import StatistiquesService from "../services/statistiquesService";
export default {
  components: {
    StatistiqueNav,
    StatistiqueMain,
  },
  data() {
    return {
      colors: [
        "#034155",
        "#d5dde1",
        "#fb4d61",
        "#FFCC00",
        "#EE8800",
        "#EE6600",
        "#DC0000",
        "#ABCC01",
        "#019934",
        "#0099BB",
        "#343399",
        "#883388",
      ],
      user: "",

      retourBack: {
        listStatBibliotheque: [
        ],
        nbLivre: 0,
        nbLivreConsult: 0,
        nbTotalConsultation: 0,
      },
    };
  },
  mounted() {
    AdminService.getUser(this.$route.params.id)
      .then((res) => {
        this.user = res.data;
      })
      .catch((error) => console.log(error));
    this.initialize();
  },
  computed: {
    dataUser() {
      return [
        {
          name: "Livres consultés",
          type: "donuts",
          loaded: this.retourBack.loaded,
          chartData: this.consulted,
        },
      ];
    },
    consulted() {
      let chartData = {
        labels: ["Livres consultés", "Livres non consultés"],
        datasets: [
          {
            backgroundColor: [this.colors[0], this.colors[1]],
            data: [this.retourBack.nbLivreConsult, this.retourBack.nbLivre -this.retourBack.nbLivreConsult],
            datalabels: {
              color: [this.colors[1], this.colors[0]],
            },
          },
        ],
      };
      return chartData;
    },
  },
  methods: {
    initialize() {
      this.loading = true;
      StatistiquesService.postStatsUtilisateur(this.$route.params.id).then(
        (element) => {
          this.retourBack = element.data;
          this.retourBack.loaded = true;
          
        }
      );
    },
  },
};
</script>

<style>
</style>
